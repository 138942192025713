<template>
  <div
    :class="[
      {'bg-white shadow-md rounded-xl focus:outline-none border border-neutral-100': searchresult || normal || wishlist },
      {'bg-white rounded-xl focus:outline-none border border-neutral-50': objectslider || objectslidersmall},
      'relative text-left overflow-hidden flex flex-col h-full'
    ]"
  >
    <div class="relative">
      <div
        :class="['w-full overflow-hidden animate-pulse']"
      >
        <div :class="[ {'rounded-t-xl ' : objectslider || objectslidersmall }, {'aspect-[24/52]' : objectslidersmall }, {'aspect-[16/10]' : !objectslidersmall }, 'skeleton object-cover object-center w-full' ]" />
      </div>
    </div>
    <div
      :class="[
        {'px-4 py-2' : searchresult || normal || wishlist },
        {'px-4 pt-2 pb-3' : objectslider || objectslidersmall },
        'flex flex-col flex-1 justify-between animate-pulse']"
    >
      <div v-if="!objectslider && !objectslidersmall" class="mb-2">
        <div class="skeleton w-full h-[1.125rem] rounded-full" />
      </div>
      <div>
        <div>
          <div class="skeleton w-full h-[0.875rem] rounded-full mb-1" />
        </div>
        <div class="mb-2">
          <div :class="[ {'skeleton w-full h-[1.125rem] rounded-full': objectslider || objectslidersmall }, {'skeleton w-full h-[0.875rem] rounded-full': !objectslider && !objectslidersmall} ]" />
        </div>
        <div v-if="objectslider && props.lastminute" class="text-lg font-light mb-2">
          <div class="skeleton w-[110px] h-[1.125rem] rounded-full" />
        </div>
        <div v-if="objectslider || objectslidersmall" class="text-lg font-light">
          <div class="skeleton w-[110px] h-[1.125rem] rounded-full" />
        </div>
      </div>
      <div v-if="!objectslider && !objectslidersmall ">
        <div :class="['text-right text-lg flex flex-row-reverse' ]">
          <div class="skeleton w-[110px] h-[1.125rem] rounded-full" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

const props = defineProps({
  design: {
    type: String,
    default: 'normal'
  },
  lastminute: {
    type: Boolean,
    default: false
  }
})

const searchresult = computed(() => props.design === 'searchresult')
const normal = computed(() => props.design === 'normal')
const wishlist = computed(() => props.design === 'wishlist')
const objectslider = computed(() => props.design === 'objectslider')
const objectslidersmall = computed(() => props.design === 'objectslidersmall')
</script>

<style lang="scss">
.skeleton {
  @apply bg-neutral-100;
}
</style>
